/*
Theme Name: Ryan Law Theme
Theme URI: http://creativepickle.com/
Description: Custom Theme for Ryan Law LLP
Author: CreativePickle
Version: 1.0
Tags: 
*/

@import url('reset.css');
@import (less) "mixins.less";

/****LAYOUT****/
body > #container {
     flex: 1; /* 2 */
  width: 100%;
  position:relative;
  &:after{
      content: '\00a0'; /* &nbsp; */
    display: block;
    height: 0px;
    visibility: hidden;
      }
    }

body{
    background:#fff url(images/bg.png) repeat 0 0;
    display: flex;
  flex-direction: column;
  height: 100vh; /* 1, 3 */
    &.page-template-homepage.php{
        
    }
}

.central{
    width:100%;
    max-width:@centralWidth!important;
    margin-left: auto;
    margin-right:auto;
    padding:0 2% 0 2%!important;
    position:relative;
    }


#main {
    width:@mainWidth;
	padding:0;
	margin:0px auto;
    }
    
#content{
    width:@mainWidth;
	padding:0;
	.bodyfont();
	&.no-pad{
	    padding:0;
	    }
	}
	
#content-wrap{
   
    }

#main-content{
	padding:0px 10px 40px 10px;
    line-height:1.7;
    ul{
        padding:10px 0 10px 0;
        li{
            background:url(images/bullet.png) no-repeat 0 0px;
            padding:0 0 0 20px;
            margin-bottom:12px;
            position: relative;
            .icon{
            	font-size:24px;
            	color:@yellow;
            	position: absolute;
            	top:-6px;
            	left:0;
            	}
            }
        }
	a{
		color:@yellow;
		.transition();
		&:hover{color:@blue;}
		}
    img{
        border:none;
        .box-shadow-custom(-8px,8px,0px,rgba(178, 135, 9, 1.000));
        }
    h2{
    	.pathway(48px);
    	.uppercase();
    	color:@blue;
    	letter-spacing: .08em;
    	margin:10px 0 20px 0;
    	}
    h3{
    	.pathway(40px);
    	.uppercase();
    	color:@yellow;
    	letter-spacing: .08em;
    	margin:10px 0 20px 0;
    	}
    h4{
    	.pathway(32px);
    	.uppercase();
    	color:@blueDark;
    	letter-spacing: .08em;
    	margin:10px 0 20px 0;
    	}
    &.attorney{
    	.callout{
    		max-width:300px;
    		.quote{
    			display:none;
    			}
    		padding:30px 0 30px 0!important;
    		}
    	}
    }
	
#sidebar{
	padding:10px;
	}
	
.accordion-wrap{
	margin-top:55px;
	}

.toggler{
	border-bottom:3px solid @blueLight;
	.transition();
	&.active, &:hover{
		background:rgba(255,255,255,0.05);
		}
	.trigger{
		
		position:relative;
		padding:15px 40px 15px 15px;
		.transition();
		
		>div.central{
			.transition();
			background:url(images/checkmark.svg) no-repeat left center;
			background-size:120px auto;
			padding:60px 10px 60px 180px!important;
			}
		&:hover, &.active{
			cursor:pointer;
			>div.central{background-size:130px auto;}
			}
		&.active{
			
			>div.central{
				background-size:130px auto;
				}
			}
		
		h5{
			color:#fff;
			.pathway(30px);
			.uppercase();
			.bold();
			letter-spacing:.05em;
			line-height:1.3;
			}
		}
	.toggle_container{
		overflow:hidden;
		
		height:0;
		color:#fff;
		h5{
			.pathway(24px);
			.uppercase();
			.bold();
			}
		.block{
			opacity:0;
			padding:25px;
			.central{padding-left:180px!important;}
			ul{
				padding:10px 0 10px 0;
				li{
					position: relative;
					padding-left:20px;
					margin:0 0 10px 0;
					&:before{
						content: '';
						width:6px;
						height:6px;
						background:@yellow;
						.inline-block();
						position: absolute;
						top:8px;
						left:0;
						
						}
					}
				}
			}
		}
	}

a.block{
	width:100%;
	height:100%;
	position: absolute;
	top:0;
	left:0;
	display: block;
	z-index: 500;
	}
	
ul.subpage-menu{
    width:100%;
    padding:0;
    margin:0 0 40px 0;
    >li{
        display:block;
        margin:0;
        padding:0;
        >a{
            display:block;
            padding:10px;
            border-top: 2px solid @defaultBG;
            .transition(all linear 130ms);
            font-size:20px;
            .text-shadow();
            &:hover{
                background:#f1f1f1;
                padding-left:15px;
                }
            }
            &.current_page_item>a, &.current_page_ancestor>a{
                background:#f1f1f1;
                padding-left:15px;
                }
            &:first-child>a{border-top:none;}
            >ul.children{display:none;}
            &.current_page_item>ul.children, &.current_page_ancestor>ul.children{
                padding:0;
                margin:0;
                display:block;
                >li{
                    >a{
                        .italic();
                        padding:10px;
                        border-top: 2px solid #e6e6e6;
                        .transition(all linear 130ms);
                        font-size:16px;
                        .text-shadow();
                        display:block;
                        &:hover{color:@accent;}
                        }
                         &:first-child>a{border-top:none;}
                         &.current_page_item>a{color:@accent;}
                    }
                }
        }
    }

ul.document-list{
     padding:0!important;
     margin:0px 0 10px 0!important;
    li{
        background:none!important;
        padding-left:0!important;
        margin-bottom:0!important;
        a{
            display:block;
            padding:10px 0 10px 0;
            .bodyfont();
            .uppercase();
            font-weight:300;
            border-bottom:1px solid @content;
            .transition();
            i{
                .inline-block();
                font-size:20px;
                color:@content;
                margin-right:10px;
                position:relative;
                top:2px;
                }
            &:hover{
                color:@content!important;
                background:@content;
                padding-left:10px;
                }
            }
        }
    }

#footer {
	position: relative;
	width:@mainWidth;
	background:@blueXDark url(images/watermark.svg) no-repeat right center;
	background-size:auto 98%;
	
	clear:both;
	.footer-logo{
		width:90%;
		max-width:420px;
		background:@blueXDark;
		position: absolute;
		left:50%;
		.centered();
		top:-40px;
		.border-radiuses(40px,0,0,40px);
		transform: translateX(-50%);
		padding:20px 30px 20px 30px;
		img{
			width:80%;
			height: auto;
			.inline-block();
			}
		}
	} 
.city-bg{
	padding-bottom:80px;
	}	
.footer-content.central{
	text-align:center;
	color:#fff;
	padding:100px 10px 80px 10px!important;
	}
	
.location-list-item{
	>div{
		border-left:2px solid @yellow;
		text-align: left;
		padding:0 0 0 15px;
		.pathway(18px);
		.uppercase();
		letter-spacing: .05em;
		line-height: 1.6;
		span.dark{
			color:@blue;
			display: block;
			}
		h6{
			.pathway(36px);
			color:@yellow;
			padding:0;
			}
		a.maplink{
			display: block;
			color:#fff;
			.icon{
				color:@yellow;
				margin-right:10px;
				}
			&:hover{
				color:@yellow;
				}
			}
		}
	}

#copyright{
	padding:10px;
	.centered();
	background:#061c36;
	color:#194875;
	.pathway(14px);
	.uppercase();
	letter-spacing: .25em;
	a{
		color:#194875;
		&:hover{
			color:#fff;
			}
		}
	}
	
.section{
    width:100%;
    position:relative;
    min-height:200px;
    padding:20px 0 20px 0;
    line-height: 1.6;
    .sintony(16px);
    }

h5.section-head{
	.pathway(55px);
	.uppercase();
	letter-spacing: .08em;
	line-height: 1.2!important;
	color:#fff;
	margin-bottom: 35px;
	&.blue{
		color:@blue;
		span{color:@yellow;}
		}
	&.yellow{
		color:@yellow;
		span{color:#fff;}
		}
	
	}
    
.vert-center-wrap{
    display:table;
    height:100%;
    height: inherit;
    >.vert-center{
        display:table-cell;
        vertical-align:middle;
        
        }
    }
	
/**********PAGE STYLES**********/

.callout{
    display:block;
    .pathway(36px);
    .uppercase();
    position:relative;
    padding:30px 60px 0 60px;
    color:@blue;
    border-top:10px solid @yellow;
    .centered();
    line-height:1.3;
    overflow:hidden;
    letter-spacing: .08em;
    .quote{
        display:block;
        width:50px;
        height:50px;
        position:absolute;
        .pathway(300px);
        &.open{
            top:-40px;
            left:0;
            background:url(images/open-quote-callout.png) no-repeat 0 0;
            }
        
        }
    &.left{
        width:100%;
        max-width:340px;
        float:left;
        margin:0 20px 15px 0;
        padding:30px 0px 0 100px;
        text-align: left;
        }
    &.right{
        width:100%;
        max-width:340px;
        text-align: left;
        float:right;
        padding:30px 0px 30px 100px;
        margin:0 0 15px 20px;
        }
    &.center{
        width:100%;
        border-top:8px solid @yellow;
        border-bottom:8px solid @yellow;
        padding:30px 10px 30px 10px;
        margin:10px 0 20px 0;
        .quote{
            display:none!important;
            }
        }
     }

.image-scaler{
    .scaler();
    min-width:100px;
    height:auto;
    }

/***********HEADER and NAVIGATION*********/
@menuLink: #666;
@menuLinkHover: #333;

#main-header{
    background:transparent;
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    z-index: 5000;
    padding:30px 0 30px 0;
    .transition();
    img.logo{
    	width:80%;
    	max-width:240px;
    	}
    .right{
    	text-align:right;
    	}
    &.active{
    	background:@blueDark;
    	}
}

#menu-toggle{
    color:#fff;
    .inline-block();
	position: fixed;
	top:30px;
	right: 30px;
   font-size:30px;
   z-index: 9999;
   
    &.active{
    	position: fixed;
    	.bar{background:@yellow;}
    	.top.bar {
		  transform: rotate(-45deg);
		  margin-top: 25px;
		}
		.bottom.bar {
		  opacity: 0;
		  transform: rotate(45deg);
		}
	.middle.bar {
		  transform: rotate(45deg);
		  margin-top: -7px;
		}
        }
    }

#nav-container{
	overflow: hidden;
	position: fixed;
	z-index: 9000;
	width:200%;
	margin-left:-50%;
	height:200%;
	margin-top:-50%;
	padding-top:50%;
	background: rgba(1,19,35,0.95);
	transform: translateY(-300%) rotate(-55deg) translateX(-140%);
	#nav-wrap{
		width:100vw;
		height:100vh;
		margin:0px auto;
		
		position: relative;
		}
	svg{
		height:110%;
		width:auto;
		position: absolute;
		left:-5%;
		top:-2%;
		.mark-item{
			fill:rgba(36, 124, 211, 0.3);
			&.mark-right{
				transform: translateY(500px);
				}
			&.mark-left{
				transform: translateY(-500px);
				}
			}
		}
	#nav{
		width:100%;
		max-width:430px;
		position: absolute;
		bottom:60px;
		right:60px;
		padding:0;
		.navborder{
			height:0;
			display: block;
				width:2px;
				position: absolute;
				left:0;
				.transition(all linear 200ms);
				top:0;
				background:@yellow;
				z-index: 9001;
				}
		}
	
	}
#menu-main-navigation{
    list-style:none;
    padding:0;
    position: relative;
    z-index:9500;
   width:100%;
  
    >li{
		margin:0;
		
		a{
			display: block;
			.pathway(45px);
			.uppercase();
			color:#fff;
			padding:10px 5px 10px 20px;
			position: relative;
			
			line-height:1;
			font-weight:300;
			letter-spacing: .08em;
			.transition(all linear 200ms);
			span.icon{
				font-size:38px!important;
				margin-left:10px;
				position: relative;
				top:-2px;
				left:0;
				color:@yellow;
				opacity:0;
				.transition(all linear 200ms);
				}
			span.border{
				height:0;
				width:2px;
				position: absolute;
				left:0;
				.transition(all linear 200ms);
				top:0;
				z-index:9500;
				background:rgba(255,255,255,1);
				}
			&:hover{
			
				color:@yellow;
				span.icon{
					.opacity(1);
					left:8px;
					}
				span.border{
					height:100%;
					}
				}
			}
        
    	}
    }
 
ul#menu-social-navigation{
    .inline-block();
    margin:0;
    padding:0;
    li{
        .inline-block();
        color:#fff;
        font-size:30px;
        padding:0;
        margin:0;
        position:relative;
        margin:0 3px 0 3px;
        &:hover{color:@accent;}
        a{
            display:block;
            text-align:left;
            text-indent:-9999px;
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            }
        }
    }   	
/***********HEADER STYLES*******/

h1{
    color:#333;
    font-size:36px;
    padding:0 0 12px 0;
    }
    
h2{
    font-size:30px;
    color:#333;
    padding:0 0 12px 0;
    }
    
h3{
    font-size:24px;
    color:#333;
    padding:0 0 8px 0;
    }
    
h4{
    font-size:18px;
    padding:0 0 8px 0;
    }
    
/***********BUTTONS***********/

a.button, #main-content .gform_footer button{
    .bodyfont();
    display:inline-block;
    background:transparent;
    padding:10px;
    color:@content;
    position:relative;
    border:none;
    .centered();
    .text{
    	display:block;
    	padding:0px 0px 0px 0px;
    	.sintony(15px);
    	letter-spacing: .04em;
    	.bold();
    	color:@blue;
    	.uppercase();
    	transform:translateY(5px);
    	span.icon{margin-left:8px!important;.inline-block();}
    	}
    .border{
    	background:@yellow;
    	position: absolute;
    	&.topline, &.bottomline{
    		width:100%;
    		height:2px;
    		}
    	&.leftline,&.rightline{
    		width:2px;
    		height:100%;
    		}
    	&.topline{
    		top:0;
    		right:0;
    		width:0;
    		}
    	&.bottomline{
    		bottom:0;
    		right:0;
    		}
    	&.leftline{
    		top:0;
    		left:0;
    		height:0;
    		}
    	&.rightline{
    		bottom:0;
    		right:0;
    		height:0;
    		}
    	}
    &:hover{
       cursor: pointer;
        }
    &.white{
    	.text{color:#fff;}
    	}
    &.large{
    	.text{font-size:26px;}
    	}
    svg{
    	margin-left:8px;
    	}
    }


/*********HOMEPAGE**********/

.overlay{
	background:rgba(0,0,0,0.5);
	position: absolute;
	z-index:2;
	top:0;
	left:0;
	width:100%;
	height:100%;
	&.blue{background:rgba(5,43,89,0.7);}
	&.white{background:rgba(255,255,255,0.7);z-index:15;}
	}

#banner{
    position:relative;

    &.homepage{
    	height:100vh;
    	.scaler();
    
    	}
    #banner-text{
    		position: relative;
    		top:50%;
    		transform:translateY(-50%);
    		position: relative;
    		z-index:400;
    		.centered();
    		h1{
    			.inline-block();
    			.pathway(200px);
    			.centered();
    			.uppercase();
    			color:rgba(255,255,255,0.6);
    		
    		width:100%;
    		max-width: 800px;
    	
    		letter-spacing: .06em;
    		span{
    			display:block;
    			background:rgba(255,255,255,0.6);
    			padding:20px;
    			.sintony(55px);
    			.light();
    			color:#3a5574;
    			letter-spacing: .05em;
    			}
    			}
    		}
    &.subpage{
        height:480px;
        margin-bottom:60px;
        #banner-text{
        h1{
        	.inline-block();
        	line-height: .9;
        	width:auto!important;
    			.inline-block();
    			.pathway(120px);
    			.centered();
    			.uppercase();
    			color:rgba(255,255,255,0.8);
    			position: relative;
    	
    		width:100%;
    		
    		letter-spacing: .06em;
    		span{
    			display:block;
    			margin-top:10px;
    			background:rgba(255,255,255,0.8);
    			padding:10px;
    			.sintony(35px);
    			.light();
    			color:#3a5574;
    			letter-spacing: .05em;
    			}
    		a{
    			color:#3a5574;
    			&:hover{
    				color:@blueDark;
    				}
    			}
    			}
    	ul.contact-details{
    		li{
    			.inline-block();
    			.sintony(22px);
    			color:rgba(255,255,255,0.8);
    			.uppercase();
    			margin:0 5px 0 5px;
    			a{
    				color:rgba(255,255,255,0.8);
    				.transition();
    				.scale(1);
    				&:hover{
    					.scale(1.2);
    					color:rgba(255,255,255,1);
    					}
    				}
    			}
    		}
        }
        }
    }
    
ul.slick-dots{
    width:100%;
    .centered();
    position:absolute;
    bottom:-50px;
    z-index:50;
    li{
        .inline-block();
        padding:0;
        margin:0 3px 0 3px;
        button{
            text-align:left;
            background:@accent;
            width:12px;
            height:12px;
            border:none;
            outline:none;
            display:block;
            .rounded-corners(50%);
            .transition(all linear 150ms);
            text-align:left;
            text-indent:-9999px;
            &:hover{
                cursor:pointer;
                background:#fff;
                }
            }
        &.slick-active{
            button{
                background:#fff!important;
                }
            }
        }
    }
    
.banner-content{
    height:100%;
    >div{
        
        height:100%;
        background:rgba(255,255,255,0.7);
        padding:20px;
        position:relative;
       
        .bold();
        color:@accent;
        >div{
            position: relative;
              top: 50%;
              transform: translateY(-50%);
            }
        }
    h5{
        .uppercase();
        .bold();
        color:@accent;
        line-height:1!important;
        margin-bottom:20px;
        }
    
    }
    
#about-section{
	padding:120px 0 120px 0;
	background:#fff url(images/files.svg) no-repeat bottom left;
	background-size:auto 100%;
	.central{
		padding:0 0 0 400px!important;
		.centered();
		}
	}

.lead-in{
	.uppercase();
	color:@yellow;
	.bold();
	letter-spacing: .05em;
	}

#why-section{
	padding:140px 0 140px 0;
	overflow: hidden;
	.left{
		padding-left:5%;
		width:50%;
		}
	.slide-in{.opacity(0);transform:translateY(100px);}
	.textbox{
		background:rgba(4,17,32,0.8);
		border-left:2px solid @yellow;
		.border-radiuses(30px,0,0,0);
		color:#fff;
		padding:40px 60px 40px 40px;
		width:100%;
	
		}
	.image{
		width:52%;
		position: absolute;
		right:0;
		top:40%;
		
		img{
			max-width:825px;
			width: 100%;
			height:auto;
			.box-shadow-custom(10px,10px,0px,rgba(178, 135, 9, 1.000));
			}
		}
	}
	
#expertise-section{
	background: rgba(0, 62, 124, 0.85);
	padding:80px 0 80px 0;
	border-top:3px solid @blueLight;
	border-bottom:3px solid @blueLight;
	.left{
		position: relative;
		padding:150px 20px 150px 20px!important;
		svg{
			height:100%;
			width:auto;
			position: absolute;
			left:50%;
			transform:translateX(-50%);
			top:0;
			overflow: visible;
			.mark-item{
				fill:rgba(0,28,59,0.5);
				.opacity(0);
				&.mark-left{
					transform: translateY(-10px);
					}
				&.mark-right{
					transform: translateY(10px);
					}
				}
			}
		h5{position: relative;z-index: 10;}
		}
	.right{
		>.icon-item{
			
			&:last-child{
				border:none;
				}
			
			}
		}
	}
	
.icon-item{
			position: relative;
			min-height: 160px;
			padding:50px 10px 50px 150px;
			background-size:120px auto!important;
			border-bottom: 2px solid @blueLight;
			.pathway(38px);
			.uppercase();
			color:#fff;
			letter-spacing: .09em;
			line-height: 1.2;
			transform: translateY(20px);
			.opacity(0);
			.transition(all linear 150ms);
			&:last-child{
				border:none;
				}
			&:hover{
				background-size:130px auto!important;
				color:@yellow;
				padding-left:160px;
				}
			&.page{
				min-height: 200px;
				padding:0 0 30px 200px;
				transform: translateY(0px);
			.opacity(1);
			.bodyfont();
			color:@content;
			text-transform: none;
			margin-bottom: 30px;
			border-bottom:2px solid @grayLight;
			background-size:150px auto!important;
			a.readmore{display: none;}
			h6{
				.pathway(34px);
				color:@yellow;
				.uppercase();
				margin-bottom:20px;
				}
				}
			}

#latest-section{
	padding:100px 0 100px 0;
	.latest-item{
		padding:20px;
		.opacity(0);
		transform:translateY(30px);
		>div{
			background:#fff;
			padding:30px;
			color:@content;
			h6{
				color:@yellow;
				.pathway(28px);
				.uppercase();
				letter-spacing: .05em;
				margin-bottom: 20px;
				}
			a.readmore{display: none;}
			}
		}
	}	
	
#form-section{
	padding:100px 0 100px 0;
	h5.section-head{
		img{
			.inline-block();
			vertical-align: middle;
			height:38px;
			width: auto;
			margin-right:15px;
			position: relative;
			top:-4px;
			}
		}
	.centered();
	.form-toggler{
		position: relative;
		background:transparent;
		border:2px solid @yellow;
		.centered();
		.inline-block();
		padding:10px 20px 10px 20px;
		min-width:240px;
		.transition();
		&:hover{
			.rounded-corners(30px);
			}
		&.active{
			background:#fff;
			cursor: pointer!important;
			.rounded-corners(30px);
			span.toggle-text{color:@blue;}
			}
		a.block{z-index:100;}
		span.toggle-text{
			.sintony(18px);
			.uppercase();
			color:#fff;
			.centered();
			display:block;
			position: relative;
			z-index:10;
			.icon{margin-left:10px;}
			&:hover{cursor:pointer!important;}
			}
		.form-container{
			opacity:0;
			position: absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
			overflow: hidden!important;
			.centered();
			.gform_wrapper{
				width:100%!important;
				.inline-block()!important;
				padding:0!important;
				margin:0!important;
				position: relative;
				*{
					padding:0!important;
				margin:0!important;
				}
				.inline-block()!important;
				width:100%;
				ul.gform_fields{
					li{
						&.gfield_error{
							background:transparent!important;
							border:none!important;
							input[type=text]{
								width:100%!important;
								color:red;
								&::-webkit-input-placeholder {
                       color: red;
                    }
                    
                    &:-moz-placeholder { /* Firefox 18- */
                       color: red;  
                    }
                    
                    &::-moz-placeholder {  /* Firefox 19+ */
                       color: red;  
                    }
                    
                   &:-ms-input-placeholder {  
                       color: red;  
                    }
								}
							}
						}
					}
				label{
					display:none!important;
					}
				.gform_footer{
					.inline-block()!important;
					width:auto!important;
					position: absolute;
					top:8px;
					right:10px;
					button.submit{
						background:@yellow;
						
						color:#fff;
						padding:5px 20px 5px 20px!important;
						.rounded-corners(20px);
						font-size:20px;
						border:none!important;
						span{display: none!important;}
						&:hover{
							color:@blue;
							cursor: pointer;
							}
						}
					}
				input[type=text]{
					width:100%!important;
					padding:12px 100px 12px 20px!important;
					.sintony(18px);
					.rounded-corners(30px);
					color:@blue;
					outline:none;
					border:none!important;
					background:transparent!important;
					}
				.validation_error{
					display:none!important;
					}
				}
				.gform_confirmation_message{
					padding:12px 10px 10px 10px;
					.pathway(16px);
					color:@blue;
					.uppercase();
					letter-spacing: .05em;
					}
			}
		}
	&.footer-form{
		padding:100px 0 300px 0;
		h5.section-head span{color: @blue!important;}
		.form-toggler{
		
		border:2px solid @blue;
	
	
	
		&:hover{
			.rounded-corners(30px);
			}
		&.active{
		
			span.toggle-text{color:@blue;}
			}
		a.block{z-index:100;}
		span.toggle-text{
		
				color:@blue!important;
		
			}
		.form-container{
		
			.gform_wrapper{
				
			
				.gform_footer{
					
					button.submit{
						background:@blue;
					
						&:hover{
							color:@blue;
							cursor: pointer;
							}
						}
					}
			
				}
			}
		}
		}
	}


/**********ATTORNEYS********/

.slide-in{
		transform: translateY(30px);
		.opacity(0);
		}
		
#expertise{
	padding:60px 0 60px 0;
	h5.section-head{color:@yellow;}
	
	.icon-item{
		border:none;
		.centered();
		margin-right:70px;
		.inline-block();
		width:100%;
		max-width:210px;
		color:@yellow;
		padding:230px 0 0 0;
		background-size:210px auto!important;
		font-size: 32px;
		line-height: 1.2;
		.transition();
		.scale(1);
		&:hover{
			.scale(1.04)!important;
			color:@blue;
			}
		}
	}

#attorney-details{
	background:@blue;
	padding:0;
	margin-top:40px;
	}

.box-section-wrap{	
	padding:30px 0 30px 0!important;
>.box-item{
	
	>div{
		background:@grayLight;
		border-left:8px solid @yellow;
		padding:30px;
		&:nth-child(odd){
			padding-right:20px;
			}
		&:nth-child(even){
			padding-left:20px;
			}
		h5{
			.pathway(30px);
			.uppercase();
			color:@yellow;
			}
		h6{
			.uppercase();
			.bold();
			font-size: 18px;
			}
		}
	}
}

.attorney-item-wrap{    
>.attorney-item{
	position: relative;

	>div{
		min-height:400px;
			.transition();
		.scale(1);
		.title{
			width:100%;
			height:100%;
			background:rgba(5,43,89,0.5);
			position: absolute;
			left:0;
			top:0;
			>div{
				.pathway(50px);
				.uppercase();
				color:rgba(255,255,255,0.85);
				padding:20px;
				width: 100%;
				position: absolute;
				left:0;
				bottom:0;
				line-height: 1.3;
				span{
					display: block;
					border-top:2px solid rgba(255,255,255,0.7);
					margin-top:5px;
					padding-top:5px;
					font-size:30px;
					text-transform: none;
					}
				}
				.transition();
			}
		
	}
	margin-bottom:30px;
	&:hover{
		>div{.scale(1.02);}
		.title{
			background:rgba(5,43,89,0.2);
			>div{color:rgba(255,255,255,1)!important;span{border-top:2px solid #fff;}}
			}
		}
	
	&.page{
		margin-bottom: 0;
		>div{
			min-height:350px;
			
			}
		}
	}
}

#related-attorneys{
	padding:80px 0 50px 0;
	h5.section-head{
		color:@blue;
		}
	}

/**********LOCATIONS********/

#main-content .location-item{
	padding:20px!important;
	
	>div{
		background:@grayLight;
	border:1px solid @content;
	
	
	.acf-map, .gm-style{
		margin-top:0;
		height:300px;
		img{box-shadow:none!important;}
		}
	}
	}

.location-details{
	padding:25px;
	h6{
		.pathway(36px);
		.uppercase();
		color:@blue;
		}
	span.admin{
		display:block;
		.bold();
		.italic();
		}
	a.maplink{
			display: block;
			margin-top:15px;
			color:@yellow;
			.pathway(20px);
			.uppercase();
			.icon{
				color:@yellow;
				margin-right:10px;
				}
			&:hover{
				color:@yellow;
				}
			}
	&.page{
		.centered();
		padding:20px;
		}
	}
	
.acf-map.page{
	img{box-shadow:none!important;}
	width:100%;
	height:400px;
	}
	
.attorney-item{
	padding:20px!important;
	>div{
		.image{
			min-height:350px;
			}
		}
	}
	
/**********CONTACT*********/

.contact-item{
	.inline-block();
	width:90%;
	max-width:330px;
	background:#ebebeb;
	border:1px solid @blue;
	padding:20px;
	min-height:240px;
	margin:10px;
	font-size: 15px;
	h6{
		.pathway(36px);
		.uppercase();
		color:@blue;
		line-height: 1.2;
		margin-bottom: 12px;
		}
	span{
		display: block;
		margin-bottom: 5px;
		svg{
			color:@blue;
			margin-right:8px;
			}
		}
	}

/**********ITEMS and WIDGETS**************/

.item{
    width:100%;
    margin-bottom:30px;
    padding-bottom:30px;
    border-bottom:1px solid @content;
}

.widget{
    margin-bottom:30px;
    h2.widget-title{
        
    }
}
 

/********ALIGNMENT********/

/* Alignment */
.alignleft {
    display: inline;
	float: left;
	margin-right: 1.625em;
}
.alignright {
	display: inline;
	float: right;
	margin-left: 1.625em;
}
.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft,
img.alignright,
img.aligncenter {
    margin-bottom: 1.625em;
}	

.clear{
    clear:both;
    }  

.wp-caption-text{
    font-size:12px;
    .italic();
    padding-top:12px;
    }
    
figure{
    -webkit-margin-before: 0;
-webkit-margin-after: 0;
-webkit-margin-start: 0;
-webkit-margin-end: 0;
    }
    
.fixed-bg{
    background-attachment:fixed!important;
    }
    
.scaler{
    position:relative;
    -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    -o-background-size: cover!important;
    background-size: cover!important;
}

.centered{
    text-align:center;
    }