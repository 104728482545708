/* apply a natural box layout model to all elements */

/***COLORS*****/
@content: #211a1d;
@accent: #999;
@link: #333;
@linkHover: darken(@link, 10%);
@defaultBG:#e6e6e6;
@blue:#003569;
@blueDark:#0d294b;
@blueXDark:#041528;
@blueLight:#014582;
@yellow:#b28709;
@yellowDark:#b28b2c;
@grayLight:#ebebeb;

/********BODY FONT*******/
@bodyFont: 16px;

/*****DIMENSIONS*****/
@mainWidth: 100%;
@centralWidth:1100px;
@footerHeight: 200px;
@leftWidth: 600px;
@rightWidth: 300px;
@gutter: 55px;

/******MIXINS*******/
.content-box{
    -moz-box-sizing: content-box!important;
    -webkit-box-sizing: content-box!important;
    box-sizing: content-box!important;
    }
    
.text-shadow (@string: 1px 1px 1px rgba(0, 0, 0, 0.6)) {
    text-shadow: @string;
}

.text-shadow-custom (@x: 0, @y: 0, @blur: 3px, @color: rgba(0, 0, 0, 0.6)) {
  text-shadow: @arguments;
}

.box-shadow (@string: 0 0px 6px rgba(0, 0, 0, 0.6)) {
    -webkit-box-shadow: @string;
    -moz-box-shadow:    @string;
    box-shadow:         @string;
}

.box-shadow-inset (@string: inset 0 0px 2px rgba(0, 0, 0, 0.6)) {
    -webkit-box-shadow: @string;
    -moz-box-shadow:    @string;
	box-shadow:         @string;
}

.box-shadow-custom (@x: 0, @y: 0, @blur: 3px, @color: rgba(0, 0, 0, 0.6)) {
  box-shadow: @arguments;
  -moz-box-shadow: @arguments;
  -webkit-box-shadow: @arguments;
}

.no-box-shadow (@string: none) {
    -webkit-box-shadow: @string;
    -moz-box-shadow:    @string;
    box-shadow:         @string;
}


.inline-block(@align: top){
    display:inline-block;
    vertical-align:@align;
    }
    
.scaler{
    position:relative;
    -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    -o-background-size: cover!important;
    background-size: cover!important;
}

.central{
   width:100%;
    max-width:@centralWidth!important;
    margin-left: auto;
    margin-right:auto;
    padding:0 10px 0 10px!important;
    position:relative;
    }
    
.centered{
    text-align:center;
    }

.opacity (@opacity: 0.5) {
    -webkit-opacity:     @opacity;
    -moz-opacity:     	@opacity;
	opacity: 		@opacity;
}

.border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
	-webkit-border-top-right-radius:    @topright;
	-webkit-border-bottom-right-radius: @bottomright;
	-webkit-border-bottom-left-radius:  @bottomleft;
	-webkit-border-top-left-radius:     @topleft;
	-moz-border-radius-topright:        @topright;
	-moz-border-radius-bottomright:     @bottomright;
	-moz-border-radius-bottomleft:      @bottomleft;
	-moz-border-radius-topleft:         @topleft;
	border-top-right-radius:            @topright;
	border-bottom-right-radius:         @bottomright;
	border-bottom-left-radius:          @bottomleft;
	border-top-left-radius:             @topleft;
	-moz-background-clip:    padding; 
	-webkit-background-clip: padding-box; 
	background-clip:         padding-box; 
}

.rounded-corners (@radius: 5px) {
  border-radius: @radius;
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
}

.transition (@transition: all linear 150ms) {
	-webkit-transition: @transition;  
	-moz-transition:    @transition;
	-ms-transition:     @transition; 
	-o-transition:      @transition;  
}

.transition-custom (@ease: linear, @speed: 150ms) {
	-webkit-transition: all @ease @speed;  
	-moz-transition:    all @ease @speed; 
	-ms-transition:    all @ease @speed;  
	-o-transition:      all @ease @speed; 
}


.scale (@factor) {
	-webkit-transform: scale(@factor);
	-moz-transform: 	 scale(@factor);
	-ms-transform: 		 scale(@factor);
	-o-transform: 		 scale(@factor);
}

.rotate (@deg) {
	-webkit-transform: rotate(@deg);
	-moz-transform: 	 rotate(@deg);
	-ms-transform: 		 rotate(@deg);
	-o-transform: 		 rotate(@deg);
}

.gradient (@startColor: #eee, @endColor: white) {
	background-color: @startColor;
	background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
	background: -webkit-linear-gradient(top, @startColor, @endColor);
	background: -moz-linear-gradient(top, @startColor, @endColor);
	background: -ms-linear-gradient(top, @startColor, @endColor);
	background: -o-linear-gradient(top, @startColor, @endColor);
}

.fixed-bg{
    background-attachment:fixed;
    }

/*************FONTS***********/
.serif{
    font-family: 'Sintony', sans-serif;
    }

.sans-serif{
    font-family: 'Sintony', sans-serif;
    }

.bodyfont (@weight: 400, @size: @bodyFont, @spacing:.0em){
   font-family: 'Sintony', sans-serif;
    font-weight:@weight;
    font-size:@size;
    letter-spacing:@spacing;
    }

.sintony (@size: 100%) {
    font-family: 'Sintony', sans-serif;
    font-size:@size;
}

.pathway (@size: 100%) {
    font-family: 'Pathway Gothic One', sans-serif;
    font-size:@size;
}

.bold{
    font-weight:bold;
    }

.light{
	font-weight:300;
	}

.italic{
    font-style:italic;
    }
    
.uppercase{
    text-transform:uppercase;
    }